<template>
  <div class="chat-page">
    <!-- static section -->
    <v-row class="m-0" style="margin: 0px !important">
      <!-- users -->
      <v-col
        cols="12"
        md="3"
        style="border: 1px solid #66656533; background-color: #fff"
        v-if="!isOpenChat || !isMobile"
      >
        <!-- search -->
        <div>
          <inpot-serch-chat
            class="chat-serch"
            :placeholder="`${$t('search')}...`"
            :rules="[]"
            @input="searchHandler"
            icon="mdiAccount"
          />
        </div>
        <!-- users -->
        <div
          v-if="loadingChat"
          class="d-flex justify-center align-items-center h-100 mt-10"
          style="height: 300px"
        >
          <v-progress-circular
            :size="40"
            :width="3"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else class="chat-content over-scroll message-content">
          <div
            @click="
              getMessages(item);
              isOpenChat = true;
            "
            v-for="(item, i) in chats"
            :key="i"
          >
            <conv-item
              :item="item"
              :style="
                item.id == receiver_id
                  ? 'background-color:#EEEEEE !important'
                  : ''
              "
            >
            </conv-item>
          </div>
        </div>
        <div class="text-center mt-4" v-if="chats.length == 0 && !loadingChat">
          <span class="primary--text font-22 font-500">{{
            $t("noChatsFound")
          }}</span>
        </div>
      </v-col>
      <!-- content -->
      <v-col
        cols="12"
        md="9"
        style="border: 1px solid #66656533; padding: 0px"
        v-if="isOpenChat || !isMobile"
      >
        <div class="white radius-15 dark-shadow massage">
          <!-- head -->
          <div
            class="d-flex align-center justify-space-between"
            style="border-bottom: 1px solid #66656533"
          >
            <div class="d-flex align-center">
              <div class="user-avatar-large mr-3 mb-2">
                <img
                  class="object-cover"
                  :src="
                    selectedUser.image.length > 1
                      ? selectedUser.image
                      : require('@/assets/images/global/user-default.jpg')
                  "
                  alt=""
                />
              </div>

              <span class="font-22 black--text d-block mx-2">{{
                selectedUser.name
              }}</span>
            </div>

            <v-icon v-if="isMobile" class="mx-2" @click="isOpenChat = false"
              >mdi-arrow-left</v-icon
            >
          </div>
          <!-- messages -->

          <div class="message-content over-scroll" id="messages">
            <div
              v-if="loadingMessage"
              class="d-flex justify-center align mt-10"
            >
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>

            <div
              v-else-if="messages.length == 0 && !loadingMessage"
              class="d-flex flex-column align-center justify-center"
            >
              <h2 class="primary--text mt-10">
                {{ $t("selectChat") }}
              </h2>
              <div v-if="chats.length >= 3">
                <v-avatar
                  v-for="(item, i) in [chats[0], chats[1], chats[2], chats[3]]"
                  :key="i"
                  size="33"
                  style="margin-right: -15px"
                >
                  <img
                    :src="
                      item.image.length > 1
                        ? item.image
                        : require('@/assets/images/global/user-default.jpg')
                    "
                    alt="John"
                  />
                </v-avatar>
              </div>
            </div>
            <div
              v-else
              class="mb-5 px-3 d-flex flex-column"
              v-for="(message, i) in messages"
              :key="i"
            >
              <div
                class="px-4 py-3 radius-5"
                :class="
                  message.from == userID
                    ? `user-from ${$vuetify.rtl ? 'mr-auto' : 'ml-auto'}  `
                    : `user-to lightGreyText--text ${
                        $vuetify.rtl ? 'ml-auto' : 'mr-auto'
                      }`
                "
              >
                <span
                  :class="
                    message.from == userID
                      ? 'white--text  text-end'
                      : 'primary--text text-start'
                  "
                  class="font-14"
                  >{{ message.message }}</span
                >
              </div>

              <div
                class="px-3"
                :class="message.from == userID ? 'text-end' : 'text-start'"
              >
                <span class="lightGreyText--text font-16 d-block mt-2">{{
                  $moment(message.created_at).calendar()
                }}</span>
              </div>
            </div>
          </div>

          <!-- input -->
          <div class="d-flex" :class="$vuetify.rtl ?'transform_icon' : ''" style="height: 58px;">
            <v-text-field
              v-model="message"
              class="mx-2 mt-3 radius-15"
              append-icon="mdi-send"
              clear-icon="mdi-close-circle"
              dense
              height="40px"
              max-height="40px"
              full-width
              color="purple"
              :placeholder="$t('navbar.message')"
              type="text"
              :rows="1"
              outlined
              @click:append="sendMessage"
              @click:clear="clearMessage"
              @keypress.enter.prevent="sendMessage"
              no-resize
            >
            </v-text-field>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InpotSerchChat from "@/components/base/InpotSerchChat.vue";
import ConvItem from "../../../components/chat/conv-item.vue";
// import Messages from "../../../components/chat/messages.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    InpotSerchChat,
    ConvItem,
    // Messages,
  },
  data: () => ({
    userAvatar: require("@/assets/images/global/user-default.jpg"),
    show: false,
    message: "",
    iconIndex: 0,
    messages: [],
    chats: [],
    castomChat: [],
    chatsCopy: [],
    receiver_id: "",
    selectedUser: { image: "" },
    loadingMessage: false,
    loadingChat: false,
    isOpenChat: false,
  }),
  computed: {
    userID() {
      return localStorage.getItem("userID");
    },
    ...mapGetters(["selectedIdChate"]),
    getCastomChat() {
      // return this.chat.slice(3);
      if (this.chat.length > 3) return this.chat.slice(3);
      else return this.chat.slice(this.chat.length);
    },
  },

  watch: {
    selectedIdChate() {
      this.getUdateChate();
      this.getAllChatsOpenChat();
    },
  },

  methods: {
    searchHandler(event) {
      this.chats = [
        ...this.chatsCopy.filter((chat) => chat.name.indexOf(event) > -1),
      ];
    },

    clearMessage() {
      this.message = "";
    },
    async sendMessage() {
      if (!this.message) return;
      let formData = new FormData();
      formData.append("message", this.message);
      formData.append("receiver_id", this.receiver_id);
      const { data } = await this.$axios.post("/sendMessage", formData);
      if (data.success) {
        this.messages.push({
          message: this.message,
          from: this.userID,
          created_at: this.$moment(),
        });
        this.clearMessage();
        setTimeout(() => {
          let messageDiv = document.getElementById("messages");
          messageDiv.scrollTop = messageDiv.scrollHeight;
        }, 5);
        await this.getAllChats(false);
        await this.getUdateChate();
      } else {
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "error",
        });
      }
    },
    async getMessages(item) {
      if (item) {
        this.$router.push({ name: "Profile-Chat-de", params: { id: item.id } });
        this.receiver_id = item.id;

        this.selectedUser = item;
      } else {
        if (this.$route.params.id) {
          this.receiver_id = this.$route.params.id;
          this.chats.forEach((chat) => {
            if (chat.id == this.receiver_id) {
              this.selectedUser = chat;
            }
          });
        }
      }
      this.message = "";
      this.loadingMessage = true;
      try {
        await this.$axios.get("/getMessage/" + this.receiver_id).then((res) => {
          if (res.data.success) {
            this.messages = [...res.data.data.chat];
            setTimeout(() => {
              let messageDiv = document.getElementById("messages");
              messageDiv.scrollTop = messageDiv.scrollHeight;
            }, 10);
            this.loadingMessage = false;
          }
        });
      } catch (error) {
        this.loadingMessage = false;
      }
    },
    async getUdateChate() {
      await this.$axios.get("/getMessage/" + this.receiver_id).then((res) => {
        if (res.data.success) {
          this.messages = [...res.data.data.chat];
          setTimeout(() => {
            let messageDiv = document.getElementById("messages");
            messageDiv.scrollTop = messageDiv.scrollHeight;
          }, 10);
          this.loadingMessage = false;
        }
      });
    },
    async getAllChats(isLoading) {
      if (isLoading) {
        this.loadingChat = true;
      }
      await this.$axios.get("/getAllChats").then((res) => {
        if (res.data.success) {
          this.loadingChat = false;
          this.chats = res.data.data.chat;
          this.chatsCopy = res.data.data.chat;
        }
      });
    },
    async getAllChatsOpenChat() {
      await this.$axios.get("/getAllChats").then((res) => {
        if (res.data.success) {
          this.chats = res.data.data.chat;
          this.chatsCopy = res.data.data.chat;
        }
      });
    },
  },

  async created() {
    await this.getAllChats(true);
    await this.getMessages();
    this.$store.commit("setIsUnreadMassege", false);
  },
};
</script>

<style lang="scss">
.chat-page {
  .transform_icon{
    .v-icon.v-icon.v-icon--link{
      transform: rotate(180deg)
    }
  }
  .message-content {
    min-height: 395px;
    max-height: 395px;
    overflow-x: hidden;
    overflow-y: auto;
    background: "#FAFAFA";
  }
  .chat-content {
    // height: 100%;
    max-height: 450px;
    // overflow-y: auto;
  }

  .user-avatar-mini {
    border-radius: 50%;
    overflow: hidden;
    width: 40px;
    height: 40px;
  }
  .user-avatar-large {
    border-radius: 50%;
    overflow: hidden;
    width: 45px;
    height: 45px;
    margin: 5px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .user-to,
  .user-from {
    box-shadow: 0 4px 8px 0 rgb(34 41 47 / 12%);
    background: #f4f4f7;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    white-space: normal; //this is the one that gets you all the time

    // max-width: 350px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      width: 25px;
      height: 25px;
      transform: translate(-1%, 31%) rotate(93deg);
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      background: #f4f4f7;
      left: 0px;
    }
  }
  .user-from {
    box-shadow: 0 4px 8px 0 rgb(34 41 47 / 12%);
    background: $primary;
    opacity: 0.9;
    &::after {
      left: unset;
      right: 0px;
      background: $primary;
      transform: translate(27%, 7%) rotate(24deg);
    }
  }
}
</style>
