var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-page"},[_c('v-row',{staticClass:"m-0",staticStyle:{"margin":"0px !important"}},[(!_vm.isOpenChat || !_vm.isMobile)?_c('v-col',{staticStyle:{"border":"1px solid #66656533","background-color":"#fff"},attrs:{"cols":"12","md":"3"}},[_c('div',[_c('inpot-serch-chat',{staticClass:"chat-serch",attrs:{"placeholder":((_vm.$t('search')) + "..."),"rules":[],"icon":"mdiAccount"},on:{"input":_vm.searchHandler}})],1),(_vm.loadingChat)?_c('div',{staticClass:"d-flex justify-center align-items-center h-100 mt-10",staticStyle:{"height":"300px"}},[_c('v-progress-circular',{attrs:{"size":40,"width":3,"color":"primary","indeterminate":""}})],1):_c('div',{staticClass:"chat-content over-scroll message-content"},_vm._l((_vm.chats),function(item,i){return _c('div',{key:i,on:{"click":function($event){_vm.getMessages(item);
            _vm.isOpenChat = true;}}},[_c('conv-item',{style:(item.id == _vm.receiver_id
                ? 'background-color:#EEEEEE !important'
                : ''),attrs:{"item":item}})],1)}),0),(_vm.chats.length == 0 && !_vm.loadingChat)?_c('div',{staticClass:"text-center mt-4"},[_c('span',{staticClass:"primary--text font-22 font-500"},[_vm._v(_vm._s(_vm.$t("noChatsFound")))])]):_vm._e()]):_vm._e(),(_vm.isOpenChat || !_vm.isMobile)?_c('v-col',{staticStyle:{"border":"1px solid #66656533","padding":"0px"},attrs:{"cols":"12","md":"9"}},[_c('div',{staticClass:"white radius-15 dark-shadow massage"},[_c('div',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"border-bottom":"1px solid #66656533"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"user-avatar-large mr-3 mb-2"},[_c('img',{staticClass:"object-cover",attrs:{"src":_vm.selectedUser.image.length > 1
                    ? _vm.selectedUser.image
                    : require('@/assets/images/global/user-default.jpg'),"alt":""}})]),_c('span',{staticClass:"font-22 black--text d-block mx-2"},[_vm._v(_vm._s(_vm.selectedUser.name))])]),(_vm.isMobile)?_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){_vm.isOpenChat = false}}},[_vm._v("mdi-arrow-left")]):_vm._e()],1),_c('div',{staticClass:"message-content over-scroll",attrs:{"id":"messages"}},[(_vm.loadingMessage)?_c('div',{staticClass:"d-flex justify-center align mt-10"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):(_vm.messages.length == 0 && !_vm.loadingMessage)?_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('h2',{staticClass:"primary--text mt-10"},[_vm._v(" "+_vm._s(_vm.$t("selectChat"))+" ")]),(_vm.chats.length >= 3)?_c('div',_vm._l(([_vm.chats[0], _vm.chats[1], _vm.chats[2], _vm.chats[3]]),function(item,i){return _c('v-avatar',{key:i,staticStyle:{"margin-right":"-15px"},attrs:{"size":"33"}},[_c('img',{attrs:{"src":item.image.length > 1
                      ? item.image
                      : require('@/assets/images/global/user-default.jpg'),"alt":"John"}})])}),1):_vm._e()]):_vm._l((_vm.messages),function(message,i){return _c('div',{key:i,staticClass:"mb-5 px-3 d-flex flex-column"},[_c('div',{staticClass:"px-4 py-3 radius-5",class:message.from == _vm.userID
                  ? ("user-from " + (_vm.$vuetify.rtl ? 'mr-auto' : 'ml-auto') + "  ")
                  : ("user-to lightGreyText--text " + (_vm.$vuetify.rtl ? 'ml-auto' : 'mr-auto'))},[_c('span',{staticClass:"font-14",class:message.from == _vm.userID
                    ? 'white--text  text-end'
                    : 'primary--text text-start'},[_vm._v(_vm._s(message.message))])]),_c('div',{staticClass:"px-3",class:message.from == _vm.userID ? 'text-end' : 'text-start'},[_c('span',{staticClass:"lightGreyText--text font-16 d-block mt-2"},[_vm._v(_vm._s(_vm.$moment(message.created_at).calendar()))])])])})],2),_c('div',{staticClass:"d-flex",class:_vm.$vuetify.rtl ?'transform_icon' : '',staticStyle:{"height":"58px"}},[_c('v-text-field',{staticClass:"mx-2 mt-3 radius-15",attrs:{"append-icon":"mdi-send","clear-icon":"mdi-close-circle","dense":"","height":"40px","max-height":"40px","full-width":"","color":"purple","placeholder":_vm.$t('navbar.message'),"type":"text","rows":1,"outlined":"","no-resize":""},on:{"click:append":_vm.sendMessage,"click:clear":_vm.clearMessage,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }