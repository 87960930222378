<template>
  <div class="py-2 px-1 radius-5 white cursor-pointer">
    <v-row align="center">
      <v-col cols="2">
        <div class="user-avatar-mini mx-auto">
          <img
            width="100%"
            height="100%"
            class="object-cover"
            :src="
              item.image.length > 1
                ? item.image
                : require('@/assets/images/global/user-default.jpg')
            "
            alt=""
          />
        </div>
        <!-- {{ item.image }} -->
      </v-col>
      <v-col cols="6">
        <span
          class="font-22 black--text d-block pl-2"
          :class="item.is_read ? '' : 'font-700'"
          >{{ item.name }}
        </span>
        <span
          class="font-15 black--text d-block pl-2"
          :class="item.is_read ? 'font-300' : 'font-400 lightGreyText--text'"
          >{{
            item.lastMessage.length >= 10
              ? item.lastMessage.slice(0, 10) + "..."
              : item.lastMessage
          }}
        </span>
        <!-- {{item}} -->
      </v-col>
      <v-col cols="4" class="text-end px-4">
        <v-badge
          :value="
            item.is_read == 0 ? true : false
          "
          color="primary"
          offset-x="5"
          dot
        >
          <span class="lightGreyText--text font-12 font-100">
            {{ $moment(item.created_at).locale("ar").fromNow() }}
          </span>
        </v-badge>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    userAvatar: require("@/assets/images/global/user-default.jpg"),
    item: Object,
  },
};
</script>