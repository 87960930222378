<template>
    <div class="base-input input-style-chat relative" >
      <v-text-field
        class="w-100"
        :class="isBudget ? 'base-budget': ''"
        :type="type"
        outlined
        :placeholder="placeholder"
        dense
        height="40px"
        max-height="40px"
        :rules="rules"
        :hide-details="hideDetails"
        :value="value"
        @input="$emit('input', $event)"
        :rounded="rounded"
        full-width
        color="purple"
        :readonly="readonly"
      >
  
      </v-text-field>
<!-- <span  class="budget font-15 purple--text d-inline-block">111</span> -->
   
    </div>
  </template>
  
  <script>
  export default {
    props: {
      placeholder: {
        type: String,
        default: "",
      },
      value: {
        validator: () => true,
      },
      type: {
        type: String,
        default: "text",
      },
      hideDetails: {
        type: Boolean,
        default: true,
      },
      rounded: {
        type: Boolean,
        default: false,
      },
      isBudget: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: "",
      },
      rules: {
        type: Array,
        default: () => [(v) => !!v || "this field is require"],
      },
      isSearch: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      darkMood() {
        return this.$store.getters["darkMood"];
      },
    },
  };
  </script>
  
  <style lang="scss">
  .input-style-chat {
    .v-input__slot {
      border-radius: 25px;
      fieldset {
        height: 40px !important;
        border: 1px solid rgba(0,0,0,.1) !important;
        // box-shadow: 0px 2px 10px rgb(168 168 168 / 5%);
        background: #eeeeee !important;
      }
      .v-input__append-inner {
        margin-top: 3px !important;
      }
      .v-text-field__slot {
        height: 37px !important;
      }
    }
    .error--text {
      .v-input__slot {
        fieldset {
          height: 49px !important;
          border: 1px solid #e44358 !important;
        }
      }
    }
  }
  .base-budget input::-webkit-outer-spin-button,
  .base-budget input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  
  /* Firefox */
  .base-budget input[type=number] {
    -moz-appearance: textfield !important;
  }
  .budget {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    padding-left: 10px;
    height: 100%;
    line-height: 44px;
    display: flex;
    &::after {
      content: "";
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-55%);
      width: 1px ;
      background: white;
      height: 70%;
  
    }
  }
  </style>
  